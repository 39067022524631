import React, { useEffect, useState } from 'react'
import {graphql} from 'react-apollo'
import {gql} from 'graphql.macro'
import Loading from 'Components/Loading'
import {route} from 'router'
import List from './ListRe'

import * as compose from 'lodash/flowRight'

import './Alerts.scss'

const GET_DEVICES_SKU = gql`
  query GET_DEVICES_SKU($query: String, $limit: Int, $offset: Int, $sortBy: String, $sortDirection: String) {
    me {
      id
      firstName
      lastName
      isSuperUser
      isManager
      viewInactiveCompanies
      company {
        id
        name
      }
    }
    devicesRe(query: $query, limit: $limit, offset: $offset, sortBy: $sortBy, sortDirection: $sortDirection) {
      id
      name
      path
      defaultTag
      companyID
      modemType
      firmwareVersion
      signalStrength
      lastTimeMessageReceived
      latitude
      longitude
      sku
      company {
        name
      }
    }
  }
`

const SKU = ({data: {loading, error, refetch, fetchMore, me, devicesRe}}) => {
  const [sortBy, setSortBy] = useState('sku')
  const [sortDirection, setSortOrder] = useState('desc')
  const [isEnd, setIsEnd] = useState(false)

  useEffect(() => {
    paginationHandler(50, fetchMore, devicesRe, sortBy, sortDirection, setIsEnd)
  },[fetchMore, devicesRe, sortBy, sortDirection])
  
  const handleSort = (field) => {
    let order
    order = sortDirection === 'asc' ? 'desc' : 'asc'
    setSortBy(field)
    setSortOrder(order)

    setIsEnd(false)
    refetch({limit: 50, offset: 0, sortBy: field, sortDirection: order})
  }
  
  if (error) return 'error'
  if (loading) return <Loading />
  if (!me?.isSuperUser) {
    route('/alarms')
  } 

  return (
    <>{!!me?.isSuperUser && (
      <div className='directory-body'>
        <div className='directory-top-bar'>
          <p className='title2344'>SKU VIEW</p>
        </div>
      <List devices={devicesRe} isSuperUser={ me?.isSuperUser} sorter={handleSort} on={sortBy} direction={sortDirection} isEnd={isEnd} type={'sku'}/>
      </div>)}
    </>
  )
}

const paginationHandler = (limit, fetchMore, data, sortBy, sortDirection, setIsEnd) => {
  const observer = new IntersectionObserver(
    ([entry]) => {
      if (entry.isIntersecting) {
        fetchMore({
          variables: { limit: limit, offset: data.length, sortBy, sortDirection},
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            if (fetchMoreResult?.devicesRe?.length === 0) setIsEnd(true)
            return {
              me: prev.me,
              devicesRe: [...prev.devicesRe, ...fetchMoreResult.devicesRe]
            }
          }
        })
        observer.unobserve(entry.target)
      }
    })

  const element = document.querySelector('.end')

  if(element) observer.observe(element)
}

export default compose(
   graphql(GET_DEVICES_SKU, {
      options: () => ({
        variables: {query: 'sku', limit: 50, offset: 0, sortBy: 'sku', sortDirection: 'desc'}
      })
    })
)(SKU)
